import type {
    PricingPlanPayload,
    PricingPlan,
    PricingPlanVersion,
    Customer,
} from '@solvimon/types';
import { handleResponse } from './lib';
import { del, get, patch, post } from '@/callcenter';
import { serializeQueryParams, withPagination } from '@/utils/api';

const ENDPOINT = `${import.meta.env.CONFIG_URL}/pricing-plans`;
const PAGE_SIZE = 15;

/**
 * Get a pricing plan
 */
export const getPricingPlan = (id: PricingPlan['id']) => {
    return handleResponse<PricingPlan>({
        request: get(`${ENDPOINT}/${id}`),
    });
};

/**
 * Create a pricing plan
 */
export const createPricingPlan = (data: PricingPlanPayload) => {
    return handleResponse<PricingPlan>({
        request: post(`${ENDPOINT}`, data),
    });
};

export const updatePricingPlan = (
    pricingPlanId: PricingPlan['id'],
    pricingDetails: Partial<PricingPlanPayload>
) => {
    return handleResponse<PricingPlan>({
        request: patch(`${ENDPOINT}/${pricingPlanId}`, pricingDetails),
    });
};

/**
 * Delete a pricing plan
 */
export const deletePricingPlan = (id: PricingPlan['id']) => {
    return handleResponse({ request: del(`${ENDPOINT}/${id}`) });
};

/**
 * Get list of all pricing plans.
 */
export const getPricingPlans = (options?: { page?: number; pageSize?: number }) => {
    const queryParams = withPagination(
        {},
        { page: options?.page, pageSize: options?.pageSize ?? PAGE_SIZE }
    );

    return handleResponse<PricingPlan>({
        request: get(`${ENDPOINT}${serializeQueryParams(queryParams)}`),
        isCollection: true,
    });
};

/**
 * Search a pricing plan.
 */
export type PricingPlanSearchFilterFields =
    | { name: 'status'; value: PricingPlanVersion['status'] }
    | { name: 'type'; value: PricingPlan['type'] }
    | { name: 'customer_id'; value: Customer['id'] };

export const searchPricingPlans = ({
    searchQuery,
    filter,
}: {
    searchQuery?: string;
    filter?: PricingPlanSearchFilterFields[];
}) => {
    const search = searchQuery ? { search_fields: [{ value: searchQuery }] } : {};

    if (filter) {
        Object.assign(search, { filter_fields: filter });
    }

    return handleResponse<PricingPlan>({
        request: post(`${ENDPOINT}/search`, search),
        isCollection: true,
    });
};
