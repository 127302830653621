import type { Config } from './types';

export const developmentConfig: Config = {
    apiUrl: 'https://beta.api.solvimon.com',
    environment: 'DEV',
    integrations: {
        embeddable: {
            baseUrl: 'https://api.eu.embeddable.com/',
        },
    },
};
