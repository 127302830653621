<script setup lang="ts">
// eslint-disable-next-line no-restricted-imports
import { Button as OriginalButton } from '@solvimon/ui';
import { computed } from 'vue';
import { omit } from 'lodash-es';
import { useRouter } from 'vue-router';
import type { ButtonProps } from './Button.types';
import { isExternalUrl } from '@/utils/url';
import { useRbac } from '@/plugins';

const props = defineProps<ButtonProps>();
const { isRouteAccessible } = useRbac();
const router = useRouter();

const isButtonAccessible = computed(() => {
    if ('to' in props && props.to && props.isRbac) {
        const resolvedRoute = router.resolve(props.to);
        return isRouteAccessible(resolvedRoute);
    }
    return true;
});

const componentProps = computed(() => {
    if ('href' in props && props.href) {
        const isExternal = isExternalUrl(props.href);

        return {
            ...omit(props, 'to', 'disabled', 'loading'),
            tag: 'a',
            target: props.target ?? isExternal ? '_blank' : '_self',
            rel: props.rel ?? isExternal ? 'noreferrer noopener' : undefined,
        };
    }

    if ('to' in props && props.to) {
        isRouteAccessible(props.to);
        return {
            ...omit(props, 'href', 'target', 'rel', 'disabled', 'loading'),
            to: props.to,
            tag: 'RouterLink',
        };
    }

    return { ...omit(props, 'to', 'href', 'target', 'rel'), tag: 'button' };
});
</script>

<template>
    <OriginalButton v-if="isButtonAccessible" v-bind="componentProps">
        <template v-if="$slots.prefix" #prefix><slot name="prefix" /></template>
        <template v-if="$slots.suffix" #suffix><slot name="suffix" /></template>
        <template v-if="$slots.default" #default><slot name="default" /></template>
    </OriginalButton>
</template>
