import type { Config } from './types';

export const betaConfig: Config = {
    apiUrl: 'https://beta.api.solvimon.com',
    environment: 'BETA',
    integrations: {
        embeddable: {
            baseUrl: 'https://api.eu.embeddable.com/',
        },
    },
};
