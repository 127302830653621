<script setup lang="ts">
import { watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { DropdownPortal, TooltipPortal } from '@solvimon/ui';
import PanelView from './views/PanelView.vue';
import { useApp, useKeyCombination, usePageTitle } from './composables';
import Toaster from './components/Toaster/Toaster.vue';
import DynamicImportErrorModal from './components/DynamicImportErrorModal/DynamicImportErrorModal.vue';
import { useAuthentication } from './composables/useAuthentication';
import Portal from '@/components/Portal/Portal.vue';
import CriticalErrorModal from '@/components/CriticalErrorModal/CriticalErrorModal.vue';
import { BlockingErrorModal } from '@/components/BlockingErrorModal';

const app = useApp();
const route = useRoute();
const router = useRouter();

const routeKey = computed(() => {
    switch (route.name) {
        case 'quotes-subscription':
        case 'quotes-subscription-notes': {
            return `quotes-${route.params.subscriptionId}`;
        }
        case 'customer-detail':
        case 'customer-edit':
        case 'customer-contact-edit': {
            return `customer-${route.params.customerId}`;
        }
        case 'integrations':
        case 'integrations-add': {
            return 'integrations';
        }
        case 'integrations-configure-integration':
        case 'integrations-configure-integration-mandate': {
            return `integrations-configure=${route.params.integrationType}`;
        }
        case 'events':
        case 'events-ingest-data':
        case 'events-details': {
            return `events-meterId=${route.params.meterId}`;
        }
        default: {
            return route.path;
        }
    }
});

useKeyCombination('Shift+d+t', () => {
    app.toggleDevTools();
    router.push({ name: 'developer-tools' });
});

watch(
    () => route.query.embedded,
    (embedded) => {
        if (embedded) {
            app.setIsEmbedded(!!embedded);
        }
    },
    {
        immediate: true,
    }
);

watch(
    () => route.query,
    (query) => {
        if (query.hide_resource_ids !== undefined) {
            app.setShowResourceIds(query.hide_resource_ids !== 'true');
        }
    },
    {
        immediate: true,
    }
);

useAuthentication();
usePageTitle();
</script>

<template>
    <Portal name="modal" />
    <TooltipPortal />
    <Portal name="side-panel" />
    <DropdownPortal />
    <CriticalErrorModal />
    <BlockingErrorModal />

    <RouterView v-slot="{ Component }">
        <component :is="Component" :key="routeKey" />
        <!-- Panel view for the child level router configuration -->
        <PanelView />
    </RouterView>
    <!-- Panel view for the top level router configuration-->
    <PanelView />
    <Toaster />
    <DynamicImportErrorModal />
</template>
