import type { Config } from './types';

export const productionConfig: Config = {
    apiUrl: 'https://api.solvimon.com',
    environment: 'LIVE',
    integrations: {
        embeddable: {
            baseUrl: 'https://api.eu.embeddable.com/',
        },
    },
};
