/**
 * Show a happy holiday message in the web inspector console during the holiday season.
 * */

export default function showHolidayGreetings() {
    const today = new Date();
    const year = today.getFullYear();

    const startDate = new Date(year, 11, 6); // December 6 (11 = December, as months are zero-based)
    const endDate = new Date(year + 1, 0, 1); // January 1 of the next year

    const isHolidaySeason = today >= startDate && today <= endDate;

    if (isHolidaySeason) {
        const tree = `
           🎄
         🌟🎄🌟
        🎄🎄🎄🎄
       🌟🎄🎄🎄🌟
      🎄🎄🎄🎄🎄🎄
     🌟🎄🎄🎄🎄🎄🌟
    🎄🎄🎄🎄🎄🎄🎄🎄
        ||||||
        ||||||
`;

        const message = 'Merry Christmas and a happy new year!';

        // eslint-disable-next-line no-console
        console.log(
            `%c${tree}\n%c${message}`,
            'color: green; font-weight: bold; font-size: 16px; line-height: 1.5;',
            'color: red; font-weight: bold; font-size: 20px;'
        );
    }
}
