import { getIso8601Date } from '@solvimon/ui';
import type {
    ApiCollectionResponse,
    ApiErrorResponse,
    ApiResponse,
    ApiSuccessCollectionResponse,
    ApiSuccessResponse,
} from '@/callcenter/types';

export function handleResponse<T>({
    request,
    isCollection,
}: {
    request: Promise<ApiResponse<T>>;
    isCollection?: undefined | false;
}): Promise<ApiSuccessResponse<T>>;
export function handleResponse<T>({
    request,
    isCollection,
}: {
    request: Promise<ApiCollectionResponse<T>>;
    isCollection: true;
}): Promise<ApiSuccessCollectionResponse<T>>;
export function handleResponse<T>({
    request,
    isCollection,
}: {
    request: Promise<ApiResponse<T> | ApiCollectionResponse<T>>;
    isCollection?: boolean;
}): Promise<ApiSuccessResponse<T> | ApiSuccessCollectionResponse<T>> {
    return new Promise((resolve, reject) =>
        request
            .then((response) => {
                if (response && typeof response === 'object' && 'hasError' in response) {
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    reject(response as ApiErrorResponse);
                    return;
                }

                if (
                    isCollection &&
                    response &&
                    typeof response === 'object' &&
                    'data' in response
                ) {
                    resolve(response);
                    return;
                }
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                resolve(response as ApiSuccessResponse<T>);
            })
            .catch((err) => reject(err))
    );
}

export const getFormattedDateRangeFilter = (from?: string, to?: string) => {
    const formatDateFilter = (date?: string) => {
        return date ? getIso8601Date(date) : '*';
    };

    return `[${formatDateFilter(from)},${formatDateFilter(to)}]`;
};
