import { isEmpty } from 'lodash-es';

/**
 * Takes an object and removes the empty properties
 */
export const withoutEmptyProperties = <T extends {}>(
    obj: T,
    options?: { removeEmptyArrays?: boolean }
): T =>
    Object.entries(obj).reduce((acc, [key, value]) => {
        if (options?.removeEmptyArrays && Array.isArray(value) && isEmpty(value)) {
            return acc;
        }

        return value !== '' && value !== undefined ? { ...acc, [key]: value } : acc;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    }, {} as T);
