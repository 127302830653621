import type {
    AccountMembership,
    Customer,
    InviteUserPayload,
    Platform,
    UserInfo,
    UserInfoExpanded,
} from '@solvimon/types';
import { handleResponse } from './lib';
import type { RefreshToken, Token } from './identity.types';
import { get, patch, post } from '@/callcenter';
import { Headers } from '@/constants';
import { getAccessTokenParsed } from '@/utils/accessToken';
import { serializeQueryParams, withExpand, withPagination } from '@/utils/api';

const ENDPOINT = import.meta.env.IDENTITY_ENDPOINT;
export const DEFAULT_PAGE_SIZE = 15;

/**
 * fetch a paginated response with users for the provided filter options
 * @param page for which the users should be retrieved
 * @param pageSize how many records are to be returned on the page
 */
export const getUsers = async ({ page, pageSize }: { page: number; pageSize?: number }) => {
    const queryParams = withPagination(
        {},
        {
            page,
            pageSize: pageSize ?? DEFAULT_PAGE_SIZE,
        }
    );

    return handleResponse<UserInfo>({
        request: get(`${ENDPOINT}/users${serializeQueryParams(queryParams)}`),
        isCollection: true,
    });
};

/**
 * Get user information for a single user.
 */
export const getUserInfo = (userId: UserInfo['id']) => {
    return handleResponse<UserInfo>({
        request: get(`${ENDPOINT}/users/${userId}`),
    });
};

/**
 * Create user.
 */
export const createUser = (data: { username: string; password: string }) =>
    handleResponse<UserInfo>({
        request: post(`${ENDPOINT}/users`, data),
    });

/**
 * Update an existing user.
 */
export const updateUser = (
    id: UserInfo['id'],
    data: Partial<{ username: string; password: string; active: boolean }>
) =>
    handleResponse<UserInfo>({
        request: patch(`${ENDPOINT}/users/${id}`, data),
    });

/**
 * Get the expanded user information for the currently authenticated user.
 */
export const getUserInfoExpanded = (platformId?: Platform['id']) => {
    const queryParams = withExpand({
        expandParams: ['account_id', 'account_groups.account_group_id'],
    });

    return handleResponse<UserInfoExpanded>({
        request: get(`${ENDPOINT}/oauth/user-info${serializeQueryParams(queryParams)}`, {
            headers: {
                [Headers.X_PLATFORM_ID]: platformId ?? getFallbackUserInfoPlatformId(),
            },
        }),
    });
};

export const getFallbackUserInfoPlatformId = () => {
    return getAccessTokenParsed()?.platform_id ?? '';
};

/**
 * Refresh the current access token.
 */
export const getRefreshedAccessToken = () => {
    return handleResponse<RefreshToken>({
        request: post(`${ENDPOINT}/oauth/refresh-token`, undefined, {
            disableAccessCheck: true,
            notificationOnError: false,
            logoutOnUnauthorized: false,
        }),
    });
};

export const requestSignIn = ({ username, password }: { username: string; password: string }) => {
    return handleResponse<Token>({
        request: post(
            `${ENDPOINT}/oauth/token`,
            { username, password },
            {
                disableAccessCheck: true,
                notificationOnError: false,
                headers: { [Headers.AUTHORIZATION]: null, [Headers.X_PLATFORM_ID]: null },
                logoutOnUnauthorized: false,
            }
        ),
    });
};

export const requestSsoSignIn = (data: { state: string; code: string; redirect_uri: string }) => {
    return handleResponse<Token>({
        request: post(`${ENDPOINT}/oauth/callback`, data, {
            disableAccessCheck: true,
            notificationOnError: false,
            logoutOnUnauthorized: false,
            headers: { [Headers.AUTHORIZATION]: null, [Headers.X_PLATFORM_ID]: null },
        }),
    });
};

export const requestResetPassword = (email: string) => {
    return handleResponse<Token>({
        request: post(
            `${ENDPOINT}/oauth/request-password-reset`,
            { email },
            {
                disableAccessCheck: true,
                notificationOnError: false,
                logoutOnUnauthorized: false,
            }
        ),
    });
};

export const resetPassword = ({ token, password }: { token: string; password: string }) => {
    return handleResponse<Token>({
        request: post(
            `${ENDPOINT}/oauth/reset-password`,
            { token, new_password: password },
            {
                disableAccessCheck: true,
                notificationOnError: false,
                logoutOnUnauthorized: false,
            }
        ),
    });
};

/**
 * Get all acconut memberships for the user
 */
export const getAccountMemberships = (userId: UserInfo['id']) => {
    return handleResponse<AccountMembership>({
        request: get(`${ENDPOINT}/users/${userId}/memberships`),
        isCollection: true,
    });
};

/**
 * Add a membership for a user.
 */
export const addMembership = (userId: UserInfo['id'], data: Partial<UserInfo>) =>
    handleResponse<UserInfo>({
        request: post(`${ENDPOINT}/users/${userId}/memberships`, data),
    });

/**
 * Update an account membership.
 */
export const updateMembership = (
    userId: UserInfo['id'],
    membershipId: UserInfo['id'],
    data: { roles: { name: string }[] }
) =>
    handleResponse<UserInfo>({
        request: patch(`${ENDPOINT}/users/${userId}/memberships/${membershipId}`, data),
    });

/**
 * Revoke user invite
 */
export const revokeInvitation = (username: UserInfo['username']) =>
    handleResponse<UserInfo>({
        request: post(`${ENDPOINT}/users/revoke-invite`, { username }),
    });

/**
 * Get embeddable token.
 */
export const getEmbeddableToken = ({
    customerId,
    embeddableId,
}: {
    customerId?: Customer['id'];
    embeddableId: string;
}) =>
    handleResponse<Nullable<{ token: string }>>({
        request: post(`${import.meta.env.IDENTITY_ENDPOINT}/partners/embeddable/token`, {
            embeddable_id: embeddableId,
            ...(customerId ? { customer_id: customerId } : {}),
        }),
    });

/**
 * Invite user.
 */
export const inviteUser = (data: InviteUserPayload) =>
    handleResponse<UserInfo>({
        request: post(`${ENDPOINT}/users/invite`, data),
    });

/**
 * Activate user.
 */
export const activateUser = ({ token, password }: { token: string; password: string }) => {
    return handleResponse<Token>({
        request: post(
            `${ENDPOINT}/oauth/activate-user`,
            { token, new_password: password },
            {
                disableAccessCheck: true,
                notificationOnError: false,
                logoutOnUnauthorized: false,
            }
        ),
    });
};
